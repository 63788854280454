:root {
  --modal-background-color: var(--sn-stylekit-background-color);

  --editor-header-bar-background-color: var(--sn-stylekit-background-color);
  --editor-background-color: var(--sn-stylekit-editor-background-color);
  --editor-foreground-color: var(--sn-stylekit-editor-foreground-color);
  --editor-title-bar-border-bottom-color: var(--sn-stylekit-border-color);
  --editor-title-input-color: var(--sn-stylekit-editor-foreground-color);
  --editor-pane-background-color: var(--sn-stylekit-background-color);
  --editor-pane-editor-background-color: var(--sn-stylekit-editor-background-color);
  --editor-pane-editor-foreground-color: var(--sn-stylekit-editor-foreground-color);
  --editor-pane-component-stack-item-background-color: var(--sn-stylekit-background-color);

  --text-selection-color: var(--sn-stylekit-info-contrast-color);
  --text-selection-background-color: var(--sn-stylekit-info-color);

  --note-preview-progress-color: var(--sn-stylekit-info-color);
  --note-preview-progress-background-color: var(--sn-stylekit-passive-color-4-opacity-variant);

  --note-preview-selected-progress-color: var(--sn-stylekit-secondary-background-color);
  --note-preview-selected-progress-background-color: var(--sn-stylekit-passive-color-4-opacity-variant);

  --items-column-background-color: var(--sn-stylekit-background-color);
  --items-column-items-background-color: var(--sn-stylekit-background-color);
  --items-column-border-left-color: var(--sn-stylekit-border-color);
  --items-column-border-right-color: var(--sn-stylekit-border-color);
  --items-column-search-background-color: var(--sn-stylekit-contrast-background-color);
  --item-cell-selected-background-color: var(--sn-stylekit-contrast-background-color);
  --item-cell-selected-border-left-color: var(--sn-stylekit-info-color);

  --navigation-column-background-color: var(--sn-stylekit-secondary-background-color);
  --navigation-section-title-color: var(--sn-stylekit-secondary-foreground-color);
  --navigation-item-text-color: var(--sn-stylekit-secondary-foreground-color);
  --navigation-item-count-color: var(--sn-stylekit-neutral-color);
  --navigation-item-selected-background-color: rgb(253, 253, 253);

  --preferences-navigation-icon-color: var(--sn-stylekit-neutral-color);
  --preferences-navigation-selected-background-color: var(--sn-stylekit-info-backdrop-color);
  --preferences-background-color: var(--sn-stylekit-contrast-background-color);

  --dropdown-menu-radio-button-inactive-color: var(--sn-stylekit-passive-color-1);

  --panel-resizer-background-color: var(--sn-stylekit-secondary-contrast-background-color);
  --link-element-color: var(--sn-stylekit-info-color);

  --normal-button-background-color: var(--sn-stylekit-background-color);

  --popover-background-color: var(--sn-stylekit-background-color);
  --popover-border-color: var(--sn-stylekit-border-color);

  --separator-color: var(--sn-stylekit-border-color);
}
