.draggable-block-menu {
  border-radius: 4px;
  padding: 3px 1px;
  cursor: grab;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  will-change: transform;
  transition: opacity 0.3s;
}

.draggable-block-menu .icon {
  width: 0.8rem;
  height: 1.1rem;
  opacity: 0.2;
  padding-left: 4.75px;
  padding-top: 2px;
}

.draggable-block-menu:active {
  cursor: grabbing;
}

.draggable-block-menu:hover {
  background-color: var(--sn-stylekit-contrast-background-color);
}

.draggable-block-target-line {
  pointer-events: none;
  background: var(--sn-stylekit-info-color);
  height: 0.25rem;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  will-change: transform, opacity;
  transition: opacity 0.15s;
}
