$heading-height: 75px;

#editor-column {
  .locked {
    opacity: 0.8;
  }
}

.section.editor {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: hidden;
  background-color: var(--editor-background-color);
  color: var(--editor-foreground-color);
}

#error-decrypting-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 16px;
}

#error-decrypting-panel {
  max-width: 500px;
}

.content-title-bar {
  width: 100%;

  padding-top: 14px;
  padding-left: 14px;
  padding-bottom: 10px;
  padding-right: 14px;

  border-bottom: 1px solid var(--editor-title-bar-border-bottom-color);
  height: auto;

  .title {
    font-size: var(--sn-stylekit-font-size-h1);
    font-weight: bold;
    padding-top: 0px;
    padding-right: 20px; /* make room for save status */

    > .input {
      float: left;
      text-overflow: ellipsis;
      width: 100%;
      font-weight: bold;
      border: none;
      outline: none;
      background-color: transparent;
      color: var(--editor-title-input-color);

      &:disabled {
        color: var(--editor-title-input-color);
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  #save-status-container {
    position: relative;
    min-width: 16ch;
    overflow: visible;
  }

  #save-status {
    font-size: calc(var(--sn-stylekit-base-font-size) - 2px);
    text-transform: none;
    font-weight: normal;

    @media screen and (min-width: 768px) {
      text-align: right;
    }

    .desc,
    .message:not(.warning):not(.text-danger) {
      opacity: 0.35;
    }
  }

  .editor-tags {
    clear: left;
    width: 100%;
    overflow: visible;
    position: relative;
  }
}

.editor-content,
#editor-content {
  flex: 1;
  display: flex;
  tab-size: 2;
  background-color: var(--editor-pane-background-color);

  position: relative;

  #editor-iframe {
    flex: 1;
    width: 100%;
  }
}

.editor-content .editable,
#editor-content .editable,
#file-text-preview {
  overflow-y: scroll;
  width: 100%;
  background-color: var(--editor-pane-editor-background-color);
  color: var(--editor-pane-editor-foreground-color);

  border: none;
  outline: none;
  padding: 15px;
  resize: none;
}

#editor-pane-component-stack {
  width: 100%;
  // To keep glued to bottom when editor is in loading state and not visible.
  margin-top: auto;

  // When two component stack items are expired and eat up full screen, this is required to scroll them.
  // overflow: auto;
  // When expired components, without this, requires scroll
  overflow: visible;

  #component-stack-menu-bar {
    border-bottom: none;
  }

  .component-stack-item {
    width: 100%;
    position: relative;

    iframe {
      width: 100%;
      background-color: var(--editor-pane-component-stack-item-background-color);
      // we moved the border top from the .component-stack-item to the .iframe, as on parent,
      // it increases its height and caused unneccessary scrollbars on windows.
      border-top: 1px solid var(--sn-stylekit-border-color);
    }
  }
}

#note-text-editor:focus {
  box-shadow: none;
}
