.sessions-modal {
  min-width: 40vw;
  width: auto;

  h2,
  ul,
  p {
    margin: 0;
    padding: 0;
  }
  ul {
    grid-column: 1 / 3;
    display: grid;
    gap: 16px;
    grid-gap: 16px;
  }
  li {
    display: grid;
    grid-template-columns: 1fr max-content;
    border-bottom: 1px solid var(--sn-stylekit-border-color);
    padding-bottom: 16px;
    grid-column-gap: 12px;
    column-gap: 12px;
  }
  li:last-of-type {
    border: none;
    padding-bottom: 0;
  }
  li > * {
    grid-column: 1;
  }
  li button {
    grid-column: 2;
    grid-row: 1 / span 3;
    align-self: center;
  }
  .sn-component .sk-panel-content {
    padding-bottom: 1.3rem;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
  }
}

.sessions-modal-refreshing {
  grid-column: 2;
  font-weight: normal;
}
