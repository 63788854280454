@media screen and (min-width: 768px) {
  .focus-mode {
    .mac-desktop #editor-column {
      // To offset colored circles in Mac
      padding-top: 35px;
    }

    .app {
      transition: grid-template-columns 0.25s;
    }

    .mac-desktop #editor-column:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      height: 38px;
      -webkit-app-region: drag;
    }

    #editor-column {
      padding: 25px 10% 0px 10%;

      @media screen and (min-width: 992px) {
        padding: 25px 15% 0px 15%;
      }

      @media screen and (min-width: 1200px) {
        padding: 25px 20% 0px 20%;
      }

      background-color: var(--sn-stylekit-contrast-background-color);

      .editor {
        overflow: hidden;
      }

      .note-view-linking-container,
      #toggle-linking-container {
        display: none;
      }

      #editor-title-bar:hover,
      #editor-title-bar:has(:focus) {
        .note-view-linking-container,
        #toggle-linking-container {
          display: flex;
        }

        .note-view-options-buttons,
        .note-status-tooltip-container,
        #conflict-resolution-button {
          opacity: 1;
        }
      }

      .note-view-options-buttons,
      .note-status-tooltip-container,
      #conflict-resolution-button {
        opacity: 0;
      }
    }

    #editor-menu-bar {
      display: none;
    }

    #editor-pane-component-stack {
      display: none;
    }

    #footer-bar .left,
    #footer-bar .right {
      opacity: 0.08;
      transition: opacity 0.25s;
    }

    #footer-bar *:hover {
      opacity: 1;
    }

    #navigation,
    #items-column {
      will-change: opacity;
      animation: fade-out 0.5s forwards;
      transition: width 0.5s;
      transition-delay: 0s;
      width: 0px !important;
      flex: none !important;
    }

    #navigation:hover {
      flex: initial;
      width: 0px !important;
    }

    #items-column:hover {
      flex: initial;
      width: 0px !important;
    }
  }
}

.disable-focus-mode {
  #navigation,
  #items-column {
    transition: width 0.5s;
    will-change: opacity;
    animation: fade-in 0.5s forwards;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    border: none !important;
  }
}
