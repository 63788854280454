.sk-panel {
  $h-content-padding: 1.625rem;
  box-shadow: 0px 2px 5px var(--sn-stylekit-shadow-color);
  background-color: var(--sn-stylekit-background-color);
  border: 1px solid var(--sn-stylekit-border-color);
  border-radius: var(--sn-stylekit-general-border-radius);
  display: flex;
  flex-direction: column;
  overflow: auto; // to hide border-radius
  flex-grow: 1;

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  &.static {
    box-shadow: none;
    border: none;
    border-radius: 0;
  }

  .sk-panel-header {
    flex-shrink: 0; /* Don't allow to condense in height */
    display: flex;
    justify-content: space-between;
    padding: 0.89375rem $h-content-padding;
    border-bottom: 1px solid var(--sn-stylekit-contrast-border-color);
    background-color: var(--sn-stylekit-contrast-background-color);
    color: var(--sn-stylekit-contrast-foreground-color);
    align-items: center;

    .sk-panel-header-title {
      font-size: var(--sn-stylekit-font-size-h1);
      font-weight: 500;
    }

    .close-button {
      font-weight: bold;
    }
  }

  .sk-footer,
  .sk-panel-footer {
    padding: 0.8125rem $h-content-padding;
    border-top: 1px solid var(--sn-stylekit-border-color);
    box-sizing: border-box;

    &.extra-padding {
      padding: 1.625rem $h-content-padding;
    }

    .left {
      text-align: left;
      display: block;
    }

    .right {
      text-align: right;
      display: block;
    }
  }

  .sk-panel-content {
    padding: 1.3rem $h-content-padding;
    padding-bottom: 0;
    flex-grow: 1;
    overflow: scroll;
    height: 100%;

    // For Windows to hide needless scrollbars
    overflow-y: auto !important;
    overflow-x: auto !important;

    .sk-p,
    .sk-li {
      color: var(--sn-stylekit-paragraph-text-color);
      line-height: 1.3;
    }
  }
}

.sk-panel-section {
  padding-bottom: 1.3rem;
  display: flex;
  flex-direction: column;

  &.sk-panel-hero {
    text-align: center;
  }

  .sk-p:last-child {
    margin-bottom: 0;
  }

  &:not(:last-child) {
    margin-bottom: 1.21875rem;
    border-bottom: 1px solid var(--sn-stylekit-border-color);

    &.no-border {
      border-bottom: none;
    }
  }

  &:last-child {
    margin-bottom: 0.5rem;
  }

  &.no-bottom-pad {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .sk-panel-section-title {
    margin-bottom: 0.40625rem;
    font-weight: bold;
    font-size: var(--sn-stylekit-font-size-h3);
  }

  .sk-panel-section-outer-title {
    border-bottom: 1px solid var(--sn-stylekit-border-color);
    padding-bottom: 0.73125rem;
    margin-top: 1.70625rem;
    margin-bottom: 15px;
    font-size: var(--sn-stylekit-font-size-h3);
  }

  .sk-panel-section-subtitle {
    font-size: var(--sn-stylekit-font-size-p);
    font-weight: bold;
    font-size: var(--sn-stylekit-font-size-h5);
    margin-bottom: 2px;

    &.subtle {
      font-weight: normal;
      opacity: 0.6;
    }
  }

  .text-content {
    .sk-p {
      margin-bottom: 0.8175rem;
    }

    p:first-child {
      margin-top: 0.24375rem;
    }
  }
}

.sk-panel-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.325rem;

  &.centered {
    justify-content: center;
  }

  &.justify-right {
    justify-content: flex-end;
  }

  &.justify-left {
    justify-content: flex-start;
  }

  &.align-top {
    align-items: flex-start;
  }

  .sk-panel-column {
    &.stretch {
      width: 100%;
    }
  }

  &.default-padding,
  &:not(:last-child) {
    padding-bottom: 0.325rem;
  }

  &.condensed {
    padding-top: 0.1625rem;
    padding-bottom: 0.1625rem;
  }

  .sk-p {
    margin: 0;
    padding: 0;
  }
}

.vertical-rule {
  background-color: var(--sn-stylekit-border-color);
  height: 1.21875rem;
  width: 1px;
}

.sk-panel-form {
  width: 100%;
  &.half {
    width: 50%;
  }

  .form-submit {
    margin-top: 0.121875rem;
  }
}

.right-aligned {
  justify-content: flex-end;
  text-align: right;
}
